<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form ref="formQueryRef" :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-select v-model:value="searchForm.type" class="vehiclenum_orgId" option-filter-prop="children" show-search
            :not-found-content="null" allowClear @select="onSelect" @blur="handleBlur" @change="handleChange"
            @search="handleSearch" placeholder="类型">
            <a-select-option :value=0>运车统计</a-select-option>
            <a-select-option :value=1>在库统计</a-select-option>
            <a-select-option :value=2>在途统计</a-select-option>
            <!--                <a-select-option :value=4>中转统计</a-select-option>-->
            <a-select-option :value=3>到达统计</a-select-option>
            <a-select-option :value=5>出库统计</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.rowNum" v-if="searchForm.type === 0" option-filter-prop="children"
            show-search :not-found-content="null" allowClear>
            <a-select-option :value=1>始发</a-select-option>
            <a-select-option :value=2>中转</a-select-option>
            <a-select-option :value=9>到达</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }"
            @change="setEndAddress" placeholder="请选择城市" />
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.vehicleTransStatus" class="vehiclenum_orgId" option-filter-prop="children"
            show-search :not-found-content="null" allowClear placeholder="车辆状态">
            <a-select-option :value=0>全部</a-select-option>
            <a-select-option :value=1>已接单</a-select-option>
            <a-select-option :value=2>已推送</a-select-option>
            <a-select-option :value=3>运输中</a-select-option>
            <a-select-option :value=9>完成</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select v-model:value="searchForm.orderSettlement" style="width:100%" allowClear placeholder="结算方式">
            <a-select-option v-for="item in settlementModes" :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.settlementStatus" style="width:150px" placeholder="结算状态">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="2">已结算</a-select-option>
            <a-select-option :value="1">部分结算</a-select-option>
            <a-select-option :value="0">未结算</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-date-picker v-model:value="searchForm.startTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
              placeholder="开始日期" />
            ~
            <a-date-picker v-model:value="searchForm.endTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
              placeholder="结束日期" />
          </a-space>
        </a-form-item>
        <a-button type="primary" @click="onSearch">
          <template #icon>
            <SearchOutlined />
          </template>查询
        </a-button>
      </a-form>
    </template>
    <template #toolbarLeft>
      <a-form-item label="总数">
        <a-tag color="red">{{ totalNum }}条</a-tag>
      </a-form-item>
    </template>
    <template #toolbarRight>
      <a-button @click="exportData">
        导出Excel
      </a-button>
    </template>
    <a-table :columns="initColumns" :scroll="{ x: 2500, y: 520 }" :row-key="record => record.id" :data-source="listData"
      :loading="loading" :pagination="pagination" :rowKey="(record, index) => { return index }" @change="handleChange">
      <template #fileId="{ record }">
        <span v-if="record.fileId !== null">已交车</span>
        <span class="d89f8" v-if="record.fileId === null">未交车</span>
        <span v-if="record.deliveryOfficeOrgName !== null && record.deliveryOfficeOrgName !== ''">{{
          record.deliveryOfficeOrgName }}</span>
      </template>
    </a-table>
  </suy-table>
</template>
<script>

import { stockInPage, stockInPageExport, transPage, transPageExport, arrivePage, arrivePageExport } from '@/api/dataCenter/stock'
import { getOrgList } from '@/api/global'
import { toRefs, reactive, onMounted } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import SuyTable from '@/components/SuyTable'
export default {

  setup () {
    const state = reactive({
      formRef: null,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        type: 0,
        rowNum: 1
      },
      totalNum: 0,
      form: {
        quota: '',
        orgId: '',
        num: 0,
        type: 1
      },
      listData: [],
      initColumns: [],
      columns: [
        {
          title: '推送时间',
          dataIndex: 'pushTime',
          key: 'pushTime'
        },
        {
          title: '最新位置',
          dataIndex: 'latestLocation',
          key: 'latestLocation'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          key: 'unloadingOperator'
        },
        {
          title: '卸车人部门',
          dataIndex: 'unloadingOrgName',
          key: 'unloadingOrgName'
        },
        {
          title: '订单ID',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },
        {
          scopedSlots: {
            title: 'title1'
          }
        },
        {
          title: '城市',
          dataIndex: 'nowCity',
          key: 'nowCity'
        },
        {
          title: '车辆当前状态',
          dataIndex: 'vehicleTransStatus.label',
          key: 'vehicleTransStatus.label'
        },
        {
          title: '是否送车',
          dataIndex: 'isTakeOut.label',
          key: 'isTakeOut.label'
        },
        {
          title: '区域来源',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },
        {
          title: '接单人员',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '历史中转地',
          dataIndex: 'transitAddress',
          key: 'transitAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          key: 'transportType.label'
        },
        {
          title: '到收金额',
          dataIndex: 'paidAmt',
          key: 'paidAmt'
        },
        {
          title: '取车费用',
          dataIndex: 'takeOutFee',
          key: 'takeOutFee'
        },
        {
          title: '收车人',
          dataIndex: 'picker',
          key: 'picker'
        },
        {
          title: '收车人电话',
          dataIndex: 'pickerMobile',
          key: 'pickerMobile'
        },
        {
          title: '结算方式',
          dataIndex: 'orderSettlement.label',
          key: 'orderSettlement.label'
        },
        {
          title: '结算状态',
          dataIndex: 'settlementStatus.label',
          key: 'settlementStatus.label'
        }
      ],
      arrivedColumns: [
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          key: 'unloadingOperator'
        },
        {
          title: '到达时间',
          dataIndex: 'unloadingTime',
          key: 'unloadingTime'
        },
        {
          title: '到达城市',
          dataIndex: 'unloadingAddress',
          key: 'unloadingAddress'
        },
        {
          title: '推送时间',
          dataIndex: 'pushTime',
          key: 'pushTime'
        },
        {
          title: '订单编号',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '车辆品牌',
          dataIndex: 'brand',
          key: 'brand'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },
        {
          title: '车辆当前状态',
          dataIndex: 'vehicleTransStatus.label',
          key: 'vehicleTransStatus.label'
        },
        {
          title: '区域来源',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },
        {
          title: '接单人员',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '历史中转地',
          dataIndex: 'transitAddress',
          key: 'transitAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          key: 'transportType.label'
        },
        {
          title: '到收金额',
          dataIndex: 'paidAmt',
          key: 'paidAmt'
        },
        {
          title: '取车费用',
          dataIndex: 'placeInFee',
          key: 'placeInFee'
        },
        {
          title: '结算方式',
          dataIndex: 'orderSettlement.label',
          key: 'orderSettlement.label'
        },
        {
          title: '收车人',
          dataIndex: 'picker',
          key: 'picker'
        },
        {
          title: '收车人电话',
          dataIndex: 'pickerMobile',
          key: 'pickerMobile'
        },
        {
          title: '交车人',
          dataIndex: 'deliverer',
          key: 'deliverer'
        },
        {
          title: '办事处交车',
          dataIndex: 'deliveryOfficeOrg.label',
          key: 'deliveryOfficeOrg.label'
        },
        {
          title: '交车办事处',
          dataIndex: 'deliveryOfficeOrgName',
          key: 'deliveryOfficeOrgName'
        },
        {
          title: '交车类型',
          dataIndex: 'deliveryType.label',
          key: 'deliveryType.label'
        },
        {
          title: '结算状态',
          dataIndex: 'settlementStatus.label',
          key: 'settlementStatus.label'
        },
        {
          title: '申请状态',
          dataIndex: 'applyStatus.label',
          key: 'applyStatus.label'
        }
      ],
      deliveryColumns: [
        {
          title: '合同号',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '推送时间',
          dataIndex: 'pushTime',
          key: 'pushTime'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '接单人员',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '分单员',
          dataIndex: 'sourceMan',
          key: 'sourceMan'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },
        {
          title: '城市',
          dataIndex: 'nowCity',
          key: 'nowCity'
        },
        {
          title: '交车时间',
          dataIndex: 'deliveryTime',
          key: 'deliveryTime'
        },
        {
          title: '区域来源',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },
        {
          title: '到收金额',
          dataIndex: 'paidAmt',
          key: 'paidAmt'
        },
        {
          title: '收车人',
          dataIndex: 'picker',
          key: 'picker'
        },
        {
          title: '收车人电话',
          dataIndex: 'pickerMobile',
          key: 'pickerMobile'
        },
        {
          title: '交车人',
          dataIndex: 'deliverer',
          key: 'deliverer'
        },
        {
          title: '交车状态（收车条）',
          dataIndex: 'fileId',
          key: 'fileId',
          slots: {
            customRender: 'fileId'
          }
        },
        {
          title: '交车类型',
          dataIndex: 'deliveryType.label',
          key: 'deliveryType.label'
        },
        {
          title: '结算状态',
          dataIndex: 'settlementStatus.label',
          key: 'settlementStatus.label'
        },
        {
          title: '申请状态',
          dataIndex: 'applyStatus.label',
          key: 'applyStatus.label'
        }
      ],
      transColumns: [
        {
          title: '当前总运车次数',
          dataIndex: 'transCount',
          key: 'transCount'
        },
        {
          title: '发运部门',
          dataIndex: 'departureOrgName',
          key: 'departureOrgName'
        },
        {
          title: '派车人',
          dataIndex: 'departure',
          key: 'departure'
        },

        {
          title: '发运时间',
          dataIndex: 'departureTime',
          key: 'departureTime'
        },
        {
          title: '发运城市',
          dataIndex: 'departureCity',
          key: 'departureCity'
        },
        {
          title: '卸车人部门',
          dataIndex: 'unloadingOrgName',
          key: 'unloadingOrgName'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          key: 'unloadingOperator'
        },
        {
          title: '线路名称',
          dataIndex: 'lineName',
          key: 'lineName'
        },
        {
          title: '类型',
          dataIndex: 'truckType',
          key: 'truckType'
        },
        {
          title: '车辆当前状态',
          dataIndex: 'vehicleTransStatus.label',
          key: 'vehicleTransStatus.label'
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName'
        },
        {
          title: '运输车',
          dataIndex: 'carNo',
          key: 'carNo'
        },
        {
          title: '司机',
          dataIndex: 'driverName',
          key: 'driverName'
        },
        {
          title: '推送时间',
          dataIndex: 'pushTime',
          key: 'pushTime'
        },
        {
          title: '合同号',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '车辆品牌',
          dataIndex: 'brand',
          key: 'brand'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },
        {
          title: '合同金额',
          dataIndex: 'contractAmt',
          key: 'contractAmt'
        },
        {
          title: '利润',
          dataIndex: 'grossProfit',
          key: 'grossProfit'
        },
        {
          title: '利润月份',
          dataIndex: 'profitMonth',
          key: 'profitMonth'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '区域来源',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },
        {
          title: '接单人员',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '委托人',
          dataIndex: 'customerName',
          key: 'customerName'
        },
        {
          title: '委托人电话',
          dataIndex: 'customerMobile',
          key: 'customerMobile'
        },
        {
          title: '委托人类型',
          dataIndex: 'businessType.label',
          key: 'businessType.label'
        }
      ],
      orgList: [],
      settlementModes: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ]
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      if (state.searchForm.type === 0) {
        state.initColumns = state.transColumns
      } else if (state.searchForm.type === 5) {
        state.initColumns = state.deliveryColumns
      } else if (state.searchForm.type === 3) {
        state.initColumns = state.arrivedColumns
      } else {
        state.initColumns = state.columns
      }
      if (state.searchForm.type === 0) {
        transPage({
          ...state.searchForm,
          current: state.pagination.current,
          size: state.pagination.size
        }).then((res) => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.totalNum = res.data.total
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        }).finally(() => {
          state.loading = false
        })
      } else if (state.searchForm.type === 3) {
        arrivePage({
          ...state.searchForm,
          current: state.pagination.current,
          size: state.pagination.size
        }).then((res) => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.totalNum = res.data.total
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        }).finally(() => {
          state.loading = false
        })
      } else {
        stockInPage({
          ...state.searchForm,
          current: state.pagination.current,
          size: state.pagination.size
        }).then((res) => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.totalNum = res.data.total
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        }).finally(() => {
          state.loading = false
        })
      }
    }
    onMounted(loadData)
    const handleChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const exportData = () => {
      if (state.searchForm.type === 0) {
        transPageExport({ ...state.searchForm }, '发运统计-明细').then(res => {
        })
      } else if (state.searchForm.type === 3) {
        arrivePageExport({ ...state.searchForm }, '中转统计-明细').then(res => {
        })
      } else {
        stockInPageExport({ ...state.searchForm }, '库存统计-明细').then(res => {
        })
      }
    }
    getOrgList({}).then(res => { state.orgList = res.data })

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.provinceId = value[0]
      state.searchForm.cityId = value[1]
    }

    return {
      ...toRefs(state),
      onSearch,
      loadData,
      setEndAddress,
      exportData,
      handleChange
    }
  },
  components: {
    SearchOutlined,
    SuyTable
  }
}
</script>
